import { useContext, useEffect, useRef } from 'react';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';
import {
  checkNavPropBgImage,
  matchElemToPageModuleData,
  TrackableListModulesType,
  trackGridItemClickEvent,
  trackGridViewEvent,
} from '@/managers/Analytics/pageModuleEvents';
import { BodyContentModuleType } from '@/components/modules/BodyContentModule';
import { PbsKidsCatalogMediaCollection } from '@/types/pbskids-graph';

export function useListModuleTracker(modulesData: BodyContentModuleType[]|PbsKidsCatalogMediaCollection[]) {
  const { station, stationError } = useContext(StationLocalizationContext);
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (
      !firstRenderRef.current ||
      !modulesData?.length ||
      (!station?.callSign && !stationError)
    ) {
      return;
    }

    firstRenderRef.current = false;

    const observables = Array.from(document.querySelectorAll('[data-ga-observable-module]'));

    const handleClick = (ev: Event) => {
      const elem = ev.target as Element;
      const listItem = elem.closest('li');

      if (!listItem) return;

      const listEl = listItem.closest('ul[data-ga-observable-module]');

      if (!listEl) return;

      const listElChildren = listEl.querySelectorAll('li');
      const clickedItemIndex = Array.from(listElChildren || [])?.indexOf(listItem);
      const hasThumbBg = checkNavPropBgImage(listEl);

      const moduleItem = matchElemToPageModuleData(listEl, modulesData as TrackableListModulesType[]);

      const moduleIndex = Array.from(observables).indexOf(listEl);

      if (clickedItemIndex > -1 && moduleItem) {
        trackGridItemClickEvent(clickedItemIndex, moduleItem, moduleIndex, station.callSign || '', hasThumbBg);
      }
    };

    const handleIntersect = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const moduleItem = matchElemToPageModuleData(entry.target, modulesData as TrackableListModulesType[]);
          const hasThumbBg = checkNavPropBgImage(entry.target);

          if (moduleItem) {
            const moduleIndex = Array.from(observables).indexOf(entry.target);
            trackGridViewEvent(moduleItem, moduleIndex, station.callSign || '', hasThumbBg);
          }
          // Just record each view the once.
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect);

    observables.forEach((el) => {
      // Watch for module entring view
      observer.observe(el);
      // Listen for clicks on module list items
      el.addEventListener('click', handleClick, true);
    });

    return () => {
      observer.disconnect();
    };
  }, [ modulesData, station, stationError ]);
}

