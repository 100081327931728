import { sendEventToGA, sendEventToUA } from '@/managers/Analytics';
import { PbsKidsVideo, PbsKidsMultiPlatformSeries } from '@/types/pbskids-graph';

type VideoEventSrcData = {
  live: boolean
  mediaid: string
  show: string
  shownola: string
  station: string
  title: string
  type: string
}

const prepareEpisodeVideoType = (videoType: string, replacement = ''): string => {
  if (!replacement) return videoType;

  if ((/full_?(episode|length)/i).test(videoType)) {
    return replacement;
  }

  return videoType;
};

const formatUAEventLabel = (videoData: VideoEventSrcData): string => {
  let label = videoData.show || 'PBS KIDS';
  label += ' | ' + (videoData?.title || 'No-Title');
  label += ' | ' + (videoData?.mediaid || 'No-Media-ID');
  label += ' | ' + prepareEpisodeVideoType(videoData.type || 'No-Video-Type', 'Episode');
  return label;
};

const getGAEventParams = (eventName: string, videoData: VideoEventSrcData, timeViewed: number) => {
  const eventParams = <Record<string, string|number>>{
    'event': eventName.toLowerCase(),
    'show_title': videoData.show || 'PBS KIDS',
    'station_localization': videoData.station || '',
    'video_player': videoData.live ? 'KIDS National Player Livestream' : 'KIDS National Player',
    'video_title': videoData.title || '',
    'video_tp_media_id': videoData.mediaid || '',
    'video_type': prepareEpisodeVideoType(videoData.type || '', 'full_length'),
  };

  if (timeViewed) {
    eventParams['video_streaming_duration'] = timeViewed;
  }

  return eventParams;
};

const getUAEventParams = (eventName: string, videoData: VideoEventSrcData, timeViewed?: number) => {
  const now = new Date();
  const utcOffset = now.getTimezoneOffset();
  const weekdays = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];

  const event = <Record<string, string|number>>{
    hitType: 'event',
    eventAction: eventName,
    eventCategory: videoData.live ? 'Video - KIDS 24/7 Player - Playback' : 'Video - KIDS National Player - Playback',
    eventLabel: formatUAEventLabel(videoData),
  };

  if (timeViewed) {
    event.eventValue = Math.round(timeViewed);
  }

  // Setup custom dimensions. There is no dimension2 or dimension9.

  event.dimension1 = videoData.station || '';
  event.dimension3 = now.getHours().toString();

  event.dimension4 = utcOffset > 0 ?
    'GMT-' + (Math.abs(utcOffset) / 60) :
    'GMT+' + (Math.abs(utcOffset) / 60);

  event.dimension5 = now.toLocaleDateString('en-ca').replace(/-/g, '');
  event.dimension6 = now.getDay().toString();
  event.dimension7 = weekdays[now.getDay()];

  if (videoData.mediaid) {
    event.dimension8 = videoData.mediaid;
  }

  if (videoData.live) {
    event.dimension10 = 'Livestream User';
  }

  return event;
};

const trackVideoEvent = (eventName: string, videoData: VideoEventSrcData, timeViewed: number) => {
  const gaEventParams = getGAEventParams(eventName, videoData, timeViewed);
  sendEventToGA(gaEventParams);

  const uaEventParams = getUAEventParams(eventName, videoData, timeViewed);
  const syndicateToProducer = !videoData.live;

  sendEventToUA(uaEventParams);

  if (syndicateToProducer && videoData.shownola) {
    sendEventToUA(uaEventParams, videoData.shownola);
  }
};

const transformVideoDataForAnalytics = (video: PbsKidsVideo, station?: string, isLive?: boolean): VideoEventSrcData => {
  const property = video.properties?.length ? video.properties[0] as PbsKidsMultiPlatformSeries : null;
  return {
    live: isLive ?? false,
    mediaid: video.mediaManagerAsset?.legacy_tp_media_id ?? '',
    show: property?.title ?? '',
    shownola: property?.nolaRoot ?? '',
    station: station ?? '',
    title: video.mediaManagerAsset?.title ?? video.title ?? '',
    type: video.videoType ?? '',
  };
};

const triggerMediaStartGAEvent = (videoData: VideoEventSrcData): void => {
  trackVideoEvent('MediaStart', videoData, 0);
};

const triggerMediaStopGAEvent = (videoData: VideoEventSrcData, timeViewed: number): void => {
  trackVideoEvent('MediaStop', videoData, Math.round(timeViewed));
};

export {
  prepareEpisodeVideoType,
  triggerMediaStopGAEvent,
  triggerMediaStartGAEvent,
  transformVideoDataForAnalytics,
};

