import { useContext, useEffect, useState } from 'react';
import PageSectionStack from '@/components/base/PageSectionStack';
import pageSectionStackStyles from '@/components/base/PageSectionStack/PageSectionStack.module.scss';
import Head, { PageMetaDefaults } from '@/components/global/Head';
import ThemeStyles from '@/components/global/ThemeStyles';
import BodyContentModule, { BodyContentModuleType } from '@/components/modules/BodyContentModule';
import MastheadContentModule from '@/components/modules/MastheadContentModule';
import MastheadContentWrapper from '@/components/modules/MastheadContentModule/MastheadContentWrapper';
import SponsorsGrid from '@/components/modules/SponsorsGrid';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';
import { useListModuleTracker } from '@/managers/Analytics/useListModuleTracker';
import { useSetScrollbarWidthVar } from '@/managers/Layout/useSetScrollbarWidthVar';
import pageQuery from '@/queries/page/getVideoPageByUri.graphql';
import { CmsSite, BodyContentModulesVideoContentCards, BodyContentModulesVideoPlaylist, PbsKidsPage, PbsKidsTheme, PbsKidsVideosWebPage, Sponsor } from '@/types/pbskids-graph';
import { queryGraphServer } from '@/utils/graphql';
import { isPrivatePage } from '@/utils/page';
import { getResolvedPageThemeData, getThemeFromAncestors, ResolvedPageThemes, setStationThemeOverride } from '@/utils/theming-system';

type VideoPageProps = {
  hasMastheadContent: boolean,
  resolvedPageThemes: ResolvedPageThemes,
  pageData: PbsKidsVideosWebPage,
  isHomePage: boolean,
};

export async function getServerSideProps(context: { params: { 'uriParts': string[]; }; }) {
  const uri = context.params['uriParts']?.join('/') || '';

  // Some pages should only be available on debug/staging/preprod environments.
  if (isPrivatePage(uri)) {
    return {
      notFound: true,
    };
  }

  // Fetch page data from graph.
  const result = await queryGraphServer(pageQuery, { uri });

  if (!result?.pbsKidsVideosWebPageByUri) {
    return {
      notFound: true,
    };
  }

  const pageData: PbsKidsVideosWebPage = result.pbsKidsVideosWebPageByUri;
  const hasMastheadContent = !!(pageData.mastheadContentModules && pageData.mastheadContentModules.length > 0);

  const resolvedPageThemes = await getResolvedPageThemeData(CmsSite.VideosWebEn, [
    {
      themeContextName: 'page',
      themes: pageData.theme as Array<PbsKidsTheme>,
      sourceDescription: 'pageData.theme',
    },
    {
      themeContextName: 'page',
      themes: getThemeFromAncestors(pageData.ancestors as Array<PbsKidsPage>),
      sourceDescription: 'getThemeFromAncestors()',
    },
    {
      themeContextName: 'masthead',
      themes: pageData.mastheadTheme as Array<PbsKidsTheme>,
      sourceDescription: 'pageData.mastheadTheme',
      hasMastheadContent,
    },
  ]);

  return {
    props: {
      hasMastheadContent,
      resolvedPageThemes,
      pageData,
      isHomePage: (uri.length === 0),
    },
  };
}

const firstModuleType = (modules: BodyContentModuleType[]) => {
  return modules?.[0]?.__typename;
};

const firstModuleIsRow = (modules: BodyContentModuleType[]) => {
  if (firstModuleType(modules) === 'BodyContentModulesVideoContentCards' || firstModuleType(modules) === 'BodyContentModulesVideoPlaylist') {
    const firstModule = modules?.[0] as BodyContentModulesVideoContentCards | BodyContentModulesVideoPlaylist;
    return firstModule?.layout === 'row';
  }
};

export default function VideoPage({
  hasMastheadContent,
  isHomePage,
  pageData,
  resolvedPageThemes,
}: VideoPageProps) {
  const { station } = useContext(StationLocalizationContext);
  const [ currentThemes, setCurrentThemes ] = useState(resolvedPageThemes);
  const allModules = pageData.bodyContentModules as BodyContentModuleType[];
  const propertiesNavFirst = firstModuleType(allModules) === 'BodyContentModulesPropertiesNavigationBanner';

  const modules = propertiesNavFirst ? pageData.bodyContentModules?.slice(1) : pageData.bodyContentModules;
  const pageProperty = pageData?.property?.[0];

  const hasBackgroundImage = !!(currentThemes.primaryTheme.backgroundImage?.[0]?.url);
  const extraTopPadding = firstModuleIsRow(allModules) && hasBackgroundImage && !hasMastheadContent;

  const pageTitle = (() => {
    if (!isHomePage) {
      if (pageProperty?.title) {
        return `Watch ${pageProperty.title} Videos`;
      } else if (pageData.title) {
        return `${pageData.title} | Videos`;
      }
    }

    return 'Videos';
  })();

  useSetScrollbarWidthVar();
  useListModuleTracker(allModules);

  useEffect(() => {
    setCurrentThemes( setStationThemeOverride(resolvedPageThemes, station.theme?.[0]) );
  }, [ station, resolvedPageThemes ]);

  return (<>
    <Head
      pageTitle={ pageTitle }
      pageDescription={ PageMetaDefaults.DEFAULT_VIDEO_PAGE_DESCRIPTION }
      pageImageUrl={ pageProperty?.mezzanine?.[0]?.url || pageProperty?.logo?.[0]?.url || '' }
    />
    <ThemeStyles resolvedPageThemes={currentThemes} />

    <MastheadContentWrapper
      hasMastheadContent={ hasMastheadContent }
      isTopLevelPage= {isHomePage }
      pageTitle={ pageTitle }
      propertyId={ pageProperty?.id }
      resolvedPageThemes={ resolvedPageThemes }
    >
      {
        pageData.mastheadContentModules &&
        pageData.mastheadContentModules[0] &&
        <MastheadContentModule
          module={ pageData.mastheadContentModules[0] }
        ></MastheadContentModule>
      }
    </MastheadContentWrapper>

    <main>
      {propertiesNavFirst && pageData?.bodyContentModules?.[0] &&
      <BodyContentModule
        module={pageData?.bodyContentModules?.[0]}
        themeData={resolvedPageThemes}
      />}
      <PageSectionStack
        className={ extraTopPadding ? pageSectionStackStyles.backgroundOffset : ''}
      >
        {modules?.map( (module, index) => (
          module && <BodyContentModule
            key={ 'body-module-' + index }
            module={ module }
            pageId={ pageData.id }
          ></BodyContentModule>
        ))}

        {!!pageProperty?.sponsors?.length && <SponsorsGrid
          sponsors={ pageProperty?.sponsors as Sponsor[] }
          logoStyle={ resolvedPageThemes.primaryTheme.sponsorLogoStyle }
        />}
      </PageSectionStack>
    </main>
  </>);
}
