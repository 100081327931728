import BlurbModule from '@/components/modules/BlurbModule';
import CarouselModule from '@/components/modules/CarouselModule';
import FreeFormModule from '@/components/modules/FreeFormModule';
import HeaderModule from '@/components/modules/HeaderModule';
import GridLowerModule from '@/components/modules/GridLowerModule';
import LiveModule from '@/components/modules/LiveModule';
import PropertiesNavigationBanner from '@/components/modules/PropertiesNavigationBanner';
import SingleVideoModule from '@/components/modules/SingleVideoModule';
import { BodyContentModulesBodyCopy, BodyContentModulesFreeformGraphicAndText, BodyContentModulesLiveTv, BodyContentModulesPropertiesNavigationBanner, BodyContentModulesTextBlurb, BodyContentModulesVideo, BodyContentModulesVideoContentCards, BodyContentModulesVideoPlaylist, PbsKidsVideo, PropertiesNavBannerCard, VideoContentCard } from '@/types/pbskids-graph';
import { ReactElement } from 'react';
import RowLowerModule from '@/components/modules/RowLowerModule';
import { ResolvedPageThemes } from '@/utils/theming-system';
import { filterCards, filterVideos } from '@/utils/video-cards/filters';

const defaultElement = <div style={{ display: 'none' }}></div>;

export type BodyContentModuleType =
  BodyContentModulesBodyCopy |
  BodyContentModulesLiveTv |
  BodyContentModulesPropertiesNavigationBanner |
  BodyContentModulesTextBlurb |
  BodyContentModulesVideo |
  BodyContentModulesVideoPlaylist |
  BodyContentModulesFreeformGraphicAndText |
  BodyContentModulesVideoContentCards;

export default function BodyContentModule({ module, pageId, themeData }: {
  module: BodyContentModuleType,
  pageId?: string,
  themeData?: ResolvedPageThemes,
}): ReactElement {
  const useBgOptions = [ '#ffffff', null, undefined ];
  const usePropNavBackgrounds = useBgOptions.includes(themeData?.primaryTheme.featureBackgroundColor?.toLowerCase());

  switch (module.__typename) {
    case 'BodyContentModulesBodyCopy':
      return <HeaderModule
        heading={ module.heading }
        body={ module.richText }
      ></HeaderModule>;

    case 'BodyContentModulesLiveTv':
      return <LiveModule
        targetProperty={ module.properties && module.properties[0] ? module.properties[0] : null }
      ></LiveModule>;

    case 'BodyContentModulesTextBlurb':
      return <BlurbModule
        blurbCopy={ module.text }
      ></BlurbModule>;

    case 'BodyContentModulesPropertiesNavigationBanner':
      return <PropertiesNavigationBanner
        cards={ module.cards as PropertiesNavBannerCard[] }
        cardBackgrounds={ usePropNavBackgrounds }
        listId={ module.id }
      ></PropertiesNavigationBanner>;

    case 'BodyContentModulesVideo':
      if (!module.video || !module.video[0]) {
        return defaultElement;
      }

      return <SingleVideoModule
        item={module.video[0]}
      ></SingleVideoModule>;

    case 'BodyContentModulesFreeformGraphicAndText':
      return <FreeFormModule
        pageData={module}
      ></FreeFormModule>;

    case 'BodyContentModulesVideoPlaylist': {
      if (!module.collection || !module.collection[0]) {
        return defaultElement;
      }

      // If playlist belongs to a only a single property, then don't display the video's show title.
      const displayShowTitle = !(module.collection[0].properties?.length === 1);
      const videos = module.collection[0].entries as PbsKidsVideo[];
      const moduleData = {
        cardStyle: module.cardStyle as string,
        context: {
          id: module.collection[0].id,
          slug: module.collection[0].slug,
          title: module.collection[0].title,
          displayShowTitle: displayShowTitle,
        },
        items: videos.filter(filterVideos(module.cardStyle as string)),
        title: module.heading as string,
      };

      if (moduleData.items.length) {
        if (module.layout === 'grid') {
          return <GridLowerModule
            title={ moduleData.title }
            items={ moduleData.items }
            cardStyle={ moduleData.cardStyle }
            context={ moduleData.context }
            listId={ module.id }
            pageId={ pageId }
          ></GridLowerModule>;
        }

        if (module.layout === 'row') {
          return <RowLowerModule
            title={ moduleData.title }
            items={ moduleData.items }
            cardStyle={ moduleData.cardStyle }
            context={ moduleData.context }
            listId={ module.id }
            pageId={ pageId }
          ></RowLowerModule>;
        }

        if (module.layout === 'carousel') {
          return <CarouselModule
            items={ moduleData.items }
            cardStyle='mezzanine'
            context={ moduleData.context }
            listId={ module.id }
            pageId={ pageId }
          ></CarouselModule>;
        }
      }

      return defaultElement;
    }

    case 'BodyContentModulesVideoContentCards': {
      if ( !module.cards ) {
        return defaultElement;
      }

      const cards = module.cards as VideoContentCard[];
      const moduleData = {
        cardStyle: module.cardStyle as string,
        linkItems: cards.filter(filterCards(module.cardStyle as string)),
        listId: module.id,
        title: module.heading as string,
      };

      if (moduleData.linkItems.length) {
        if (module.layout === 'grid') {
          return <GridLowerModule
            title={ moduleData.title }
            linkItems={ moduleData.linkItems }
            cardStyle={ moduleData.cardStyle }
            listId={ moduleData.listId }
          ></GridLowerModule>;
        }

        if (module.layout === 'row') {
          return <RowLowerModule
            title={ moduleData.title }
            linkItems={ moduleData.linkItems }
            cardStyle={ moduleData.cardStyle }
            listId={ moduleData.listId }
          ></RowLowerModule>;
        }

        if (module.layout === 'carousel') {
          return <CarouselModule
            linkItems={ moduleData.linkItems }
            cardStyle='mezzanine'
            listId={ moduleData.listId }
          ></CarouselModule>;
        }
      }

      return defaultElement;
    }

    default:
      return defaultElement;
  }
}
